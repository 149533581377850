<template>
    <div>
        <title-bar :title="$t('navigations.projects')"/>
        <div v-if="rows" class="custom-table">
            <table-actions
                :actions="['search', 'addBtn', 'perPage']"
                :addBtnText="$t('messages.projectCreate')"
                :addBtnLink="$helper.getAddUrl('projects')"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:name="data">
                    {{ data.value.name }}
                </template>
                <template v-slot:description="data">
                    {{ data.value.description }}
                </template>
                <template v-slot:sharingDepartments="data">
                    <div class="vs__selected-options"
                         v-if="data.value.sharingDepartments && data.value.sharingDepartments.length">
            <span class="vs__selected" v-for="department in data.value.sharingDepartments" :key="department">
              {{ department.name }}
            </span>
                    </div>
                </template>
                <template v-slot:fromDate="data">
                    {{ $helper.formatDate(data.value.fromDate) }}
                </template>
                <template v-slot:toDate="data">
                    {{ $helper.formatDate(data.value.toDate) }}
                </template>
                <template v-slot:status="data">
                    <b-badge :variant="statusClass(data.value.status)">{{
                            data.value.status.replace('_', ' ')
                        }}
                    </b-badge>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn
                            :path="$helper.getEditUrl('projects', data.value.id)"
                        />
                        <delete-btn @pressDelete="deleteProject(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
    name: "AdminDeliveriesListing",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        EditBtn,
        DeleteBtn,
        TitleBar
    },
    mixins: [ResourceUtils],
    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            sortable: {
                order: "createdAt",
                sort: "desc",
            },
            columns: [
                {
                    label: this.$t("forms.name"),
                    field: "name",
                    sortable: true,
                },
                {
                    label: this.$t("forms.description"),
                    field: "description",
                    sortable: false,
                },
                {
                    label: this.$t("forms.sharingDepartments"),
                    field: "sharingDepartments",
                    sortable: false,
                },
                {
                    label: this.$t("forms.fromDate"),
                    field: "fromDate",
                    sortable: true,
                },
                {
                    label: this.$t("forms.toDate"),
                    field: "toDate",
                    sortable: true,
                },
                {
                    label: this.$t("forms.status"),
                    field: "status",
                    sortable: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.$Projects.getCollection({params}).then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.sort = sort;
            this.sortable.order = order;
            if (order === 'employee') {
                this.sortable.order = order + '.familyName';
            }
            if (order === 'owner') {
                this.sortable.order = order + '.name';
            }
            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        showDepartments(departments) {
            let text = ""
            for (let index = 0; index < departments.length; index++) {
                const element = departments[index];
                text += element.name
                if (index !== departments.length - 1) {
                    text += ', '
                }
            }
            return text
        },
        statusClass(status) {
            let className = 'success'
            if (status === 'TO_DO') {
                className = 'danger'
            }
            return className
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        deleteProject(url) {
            this.deleteByUrl(this.$Projects, url, this.$t("messages.projectDeleted"), null, this.load, this.error);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>
